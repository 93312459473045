$primary-color: #b813f2;

.list-loader{
	display: flex;
	align-content: center;
	justify-content: center;
}
.auth-loader-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 5;
	background-color: rgba(255, 255, 255, 0.7);
}

.lds-dual-ring {
	display: inline-block;
	width: 90px;
	height: 90px;

	&:after {
		content: ' ';
		display: block;
		width: 64px;
		height: 64px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid $primary-color;
		border-color: $primary-color transparent $primary-color transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.lds-dual-ring-small {
	display: inline-block;
	width: 32px;
	height: 32px;
	vertical-align: middle;

	&:after {
		content: ' ';
		display: block;
		width: 20px;
		height: 20px;
		margin: 4px;
		border-radius: 50%;
		border: 3px solid $primary-color;
		border-color: $primary-color transparent $primary-color transparent;
		animation: lds-dual-ring-small 1.2s linear infinite;
	}
}

@keyframes lds-dual-ring-small {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
