@import './scss/style.scss';
@import 'custom';
.input .input__name.input__name--character{
  background-color: #3382AC88;
  display: inline-block;
  padding: 1px 3px;
  border-radius: 2px;
  font-weight: bold;
  margin-right: 5px;
}
