html {
  box-sizing: border-box;
  height: 100%;
}

body {
  height: 100%;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

ul,
ol {
  padding: 0;
}

ul,
ol {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul[class],
ul {
  list-style: none;
}

img {
  // max-width: 100%;
  width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

a {
  text-decoration: none;
}

button {
  display: inline-block;
  cursor: pointer;
  border: none;
  padding: 0;
}

* {
  line-height: inherit;
}

