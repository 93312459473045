.rotate90{
  transform: rotate(180deg);
}
.make-proposal-previev__title{
  font-weight: 700;
  font-size: 2em;
  line-height: 160%;
  color: #3382AC;
  margin-bottom: 20px;
}
.make-proposal-previev__text{
  color: #7ea4b8;
  font-size: 1.5em;
}
.make-proposal-previev__text a {
  color: #0f6dca;
}


.voting-header {
  &__text {
      font-weight: 400;
      font-size: 14px;
      // line-height: 160%;
      // color: var(--light-theme__text-secondary);

      blockquots  {
          display: block;
          margin: 12px 0;
          padding: 4px 8px 4px 12px;
          color: var(--text-light);
          background: var(--bg-secondary);
          border: none;
          border-left: 2px solid var(--border-third);
      }
      ul {
          margin: 12px 0;
          list-style-type: disc;
          list-style-position: inside;
      }
  
      ol {
          margin: 12px 0;
          list-style-type: numeric;
          list-style-position: inside;
      }
  }
  .input .input__name.input__name--character{
    background-color: #3382AC;
  }
}

