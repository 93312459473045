.switch.switch--3el {
  width: 370px;
}

.button--no-active{
  background: #1f2231;
  color: #A7A9AD;
}

.select-token.coin-block{
  background-color: #1f2231;
}
.select-token.coin-block .coin{
  opacity: .4;
  cursor: auto;
}