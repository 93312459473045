:root {
  --main-text-color: #A7A9AD;
  --icon-main-color: #A7A9AD;
  --text-light: #fff;
  --text-accent: #B813F2;
  --text-light-2: #E5E8E8;
  --text-error: #FD3232;

  --button-text-color: #E5E8E8;
  --main-white-color: #E5E8E8;

  --bg-primary: #161522;
  --bg-secondary: #202231;
  --bg-third: #003f61;

  --main-color: #B813F2;
  --secondary-color: #3498DB;


  --border-secondary:  #202231;
  --border-third: #2E3348;

  --light-theme__text-main: #161522;
  --light-theme__text-secondary: #9996BE;
  --light-theme__text-third: #DDDCE9;
  --light-theme__text-white: #fff;


  --light-theme__bg-second: #F7F6FA;
  --light-theme__bg-third:  #DDDCE9;
  

  --light-theme-bg: #F7F6FA;

  --light-theme__border: #F7F6FA;
  --light-theme__border2: #DDDCE9;

  --light-theme__icon-color: #9996BE;
}