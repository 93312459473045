 
@import "ui__var.scss";


@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}


* {
    box-sizing: border-box;
}

button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
}

img {
    width: 100%;
}

svg {
    width: 100%;
    height: auto;
}

a {
    text-decoration: none;
}

.hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

/*--------------Inputs---------------*/


.input {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    &__name {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
    }
    &__notification {
        margin: 8px 0 0 0;
        font-size: 14px;
        color: var(--ui-error-color);
    }
    &--error {
        .input-item, .textarea{
            border: 2px solid var(--ui-error-color);
        }
    }
    &--success {
        .input-item {
            border: 2px solid var(--ui-success-color);
        }
    }
    &--numb{
        width: 40px;
        margin: 0 8px;
        .input-item{
            padding: 0;
            border: none;
            border-bottom: 2px solid var(--ui-main-color);
            border-radius: 0;
            font-size: 24px;
            text-align: center;
            &.active{
                border-color: var(--ui-input-border-color);
            }
        }
    }
    &--search-filter {
      width: 270px;
    }
}

.input-wrapper {
    position: relative;
}

.input-item {
    display: block;
    width: 100%;
    height: 44px;
    padding: 0 15px;
    border-radius: 5px;
    border: 1px solid var(--ui-input-border-color);
    outline: none;    
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    transition: all 0.15s ease;
    @include input-placeholder {
        color: #b6b6b6;
        transition: all 0.15s ease;
        font-size: 14px;
    }
    &--left-icon {
        padding-left: 45px;
    }
    &--right-double-icon {
        padding-right: 60px;
    }
    &--right-icon-and-action {
        padding-right: 90px;
    }
    &--right-icon {
        padding-right: 40px;
    }
    &--bigger {
        height: 52px;
        font-size: 16px;
    }
    &--fs16 {
        font-size: 16px;
        @include input-placeholder {           
            font-size: 16px;
        }

    }
}

.input-icon {
    position: absolute;
    top: 50%;transform: translateY(-50%);
    display: flex;
    right: 10px;
    width: 20px;
    svg {
        width: 100%;
        height: auto;
    }
    
    &--right {
        left: unset;
        right: 15px;
    }
    &--more-right {
        left: unset;
        right: 45px;
    }
}

.input-action {
    position: absolute;
    top: 50%;transform: translateY(-50%);
    display: flex;
    left: 15px;
    font-weight: 700;
    color: var(--ui-main-color);
    &--more-right  {
        left: unset;
        right: 45px
    }
    &--right {
        left: unset;
        right: 15px
    }
    
}

.password-type {
    display: none;
}

input[type="password"] ~.show-pass {
    .password-type {
        display: block;
    }
    .text-type {
        display: none;
    }
}

.show-pass {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;    
    display: flex;
    width: 16px;
    svg {
        width: 100%;
        height: auto;
    }
    .fill path {
        fill: #b6b6b6;
    }    
    .stroke path {
        stroke: #b6b6b6;
    }
}


.textarea{
    padding: 10px 15px;
    border-radius: 5px;
    font: inherit;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid var(--ui-input-border-color);
    textarea {
        
        display: block;
        width: 100%;
        min-height: 120px;
        background: none;
        border: none;
        outline: none !important;
        font-size: 14px;    
        @include input-placeholder {
            color: #b6b6b6;
            transition: all 0.15s ease;
        }
        resize: vertical;
        scrollbar-color:  #b6b6b6 transparent ;
        scrollbar-width: thin;
        
        &::-webkit-scrollbar {
          width: 6px; 
          height: 6px;          
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background: #b6b6b6;
          cursor: pointer;
        }
    }
}

.select-block {
    &__name {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
    }
}

.select {
    position: relative;
    display: flex;
    align-content: center;
    height: 40px;    
    border-radius: 5px;
    border: 1px solid var(--ui-input-border-color);
    background: #fff;
    z-index: 3;
    &__current {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 15px;
        font-size: 14px;
    }

    &__current-icon {
      position: relative;
      display: flex;
      flex: 0 0 24px;
      max-width: 24px;
      margin-right: 10px;

      .small-img {
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 15px;
        height: 15px;
        border: 1px solid #161522;
        border-radius: 50%;
      }
    }
    &__current-arrow {
        display: flex;
        flex: 0 0 8px;
        max-width: 8px;
        margin-left: 10px;
        svg {
            stroke:  #E5E8E8;
        }
    }
    &__current-close {
      display: flex;
      flex: 0 0 12px;
      max-width: 18px;
      margin-left: 8px;
      svg {
        stroke:  #E5E8E8;
      }
    }
    &__current-text {
        margin-right: auto;
    }
    &__img {       
        display: flex;
        width: 16px;
        align-items: center;
        margin-right: 12px;
    }
    &__drop {
        display: none;
        position: absolute;        
        left: 0;
        top: calc(100% + 10px);        
        width: 100%;        
        background: #161522;
        border: 1px solid #2E3348;
        border-radius: 11px;
        z-index: 1;

        ul {
            >li {
                border-bottom: 1px solid var(--border-third);
                &:first-child {
                    >a, >button {
                        border-radius:  11px 11px 0 0;
                    }
                }
                &:last-child {
                    border: none;
                    >a, >button {
                        border-radius: 0 0 11px 11px;
                    }
                }
                >a, >button {
                    display: flex;align-items: center;
                    min-height: 40px;
                    width: 100%;                    
                    //border-radius: 4px;
                    color: #E5E8E8;
                    padding: 7px 11px;
                    font-size: 14px;
                    transition: all 0.15s ease;
                    text-align: left;
                    &:hover {
                        background: #2E3348;
                    }
                }
            }
        }
    }
    &__drop-scroll {
        max-height: 180px;
        overflow: auto;
        scrollbar-color:  #b6b6b6 transparent ;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 4px; 
          height: 4px;          
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background: #b6b6b6;
        }
    }
    &.active {
        z-index: 5;
        .select__drop {
            display: block;
        }
    }
    &--small {
        padding: 0;
        border: 0;
        width: 62px;
        z-index: auto;
        .select__drop {
            padding: 0;
            min-width: 100px;
        }
    }
    &--grey {
        background: #DADADA;
        padding: 0 15px;
        width: 74px;
    }
    &--light {
        .select__current {
            color: #b6b6b6;
            font-weight: 300;
            font-size: 16px;
            line-height: 18px;
        }
    }
    &--big {
        height: 52px;
        font-size: 16px;
    }
    &--type-2 {      
        justify-content: flex-start;
        height: 48px;
        font-weight: 600;
        font-size: 16px;
        background: #161522;
        border-radius: 12px;
        border: 1px solid #2E3348;

        .select__current {
            font-size: 16px;
            color: #E5E8E8;
        }

        .select__current-arrow {
            margin-left: auto;
            flex: 0 0 16px;
            max-width: 16px;
        }
    }

    &--type-3 {
      height: 41px;
      background: transparent;
      border: none;
      justify-content: flex-start;

      .select__current {
        padding: 0;
        color: #E5E8E8;
        font-weight: 600;
        font-size: 16px;
      }
      .select__current-arrow {
          margin-left: auto;
          flex: 0 0 20px;
          max-width: 20px;
        }
    }

    &--full-width {
        width: 100%;
    }

    &--sort {
      width: 185px;
      height: 48px;
      background: #161522;
      border: 1px solid #2E3348;
      border-radius: 12px;

      .select__current {
        padding: 0 12px;
        font-weight: 600;
        font-size: 16px;
        color: #E5E8E8;
      }
      .select__current-arrow {
        max-width: 20px;
        flex-basis: 20px;
      }
    }

    &--activity {
      background: #161522;
      border-radius: 12px;
      border: none;
      .select__current {
        font-weight: 600;
        font-size: 14px;
        color: #E5E8E8;
      }
      .select__drop {
        width: 305px;
      }
      .select__current-close {
        display: none;
      }
      &.active {
        background: #850DAF;
        .select__current-close  {
          display: flex;
        }
      }
    }
}

/*--------------End_Inputs-----------*/




/*--------------Buttons-------------*/


button {
    background: none;
    border: none;
    outline: none;
    padding: 0;
}

.button {
    position: relative;
    display: inline-flex;
    align-items: center;justify-content: center;
    width: auto;
    min-width: 110px;
    min-height: 48px;
    padding: 10px 15px;
    background: var(--ui-button-bg);
    border: none;    
    border-radius: 12px;
    font-size: 16px;
    line-height: 160%;   
    font-weight: 600; 
    color: #fff;
    cursor: pointer;    
    transition: all 0.3s ease;
    &:hover {
        //background: var(--ui-button-bg-hover);
    }
    &--header-connect{
        width: 176px;
        background: #3498DB;
        color: var(--button-text-color);        
        &:hover {
            background: #2974a7;
        }
    }
    &--second-type {
      background: #3498DB;
      color: #fff;
      &:hover {
        background: #2974a7;
      }   
    }
    &--type-3 {
        background: rgba(253, 50, 50, 0.15);
        color: #FD3232;
        font-weight: 600;
        font-size: 14px;
    } 
    &--rounded {
        border-radius: 22px;
    }
    &--transparent {
      background: transparent;
      color: #3498DB;
    }
    &--fs-14 {
        font-size: 14px;
    }
    &--less-height {
        min-height: 40px;
        padding: 9px 15px;
    }
    &--big-height {
        height: 60px;
        font-size: 18px;
        border-radius: 14px;
    }
    &--medium-height {
      height: 48px;
      padding: 10px 10px;
    }
    &--full-width {
        width: 100%;
        min-width: unset;
    }
    &--small {
        width: 164px;
    }
    &--regular {
        width: 160px;
    }
    &--wide {
        width: 190px;
    }
    &--wider {
        width: 213px;
    }
    &--big {
        width: 240px;
    }
    &--center {
      display: block;
      margin: 0 auto;
    }

    &--filter {
      padding: 13px 20px;
      justify-content: space-between;

      span {
        position: absolute;
        top: 11px;
        right: 15px;
      }
    }

    &--less-min-width {
      min-width: 99px;      
    }
    &--less-min-width-2 {
      min-width: 86px;      
    }

    &--gap {
      column-gap: 8px;
    }
   
    &--mb {
      margin-bottom: 16px;
    }

    &--pad24 {
      padding: 0 24px;
    }
    
    &:disabled {
        background: #2E3348 !important;
        color: var(--main-text-color) !important;
        cursor: default;

        .button__icon svg{
          stroke: #A7A9AD;
        }
    }
    &__text {
        display: block;
        padding-left: 16px;
        &--padding-none {
            padding-right: 0;
            padding-left: 0;
        }
    }
    &__arrow {
        display: flex;
        align-items: center;
        margin: 0 9px;
        width: 6px;        
    }
    &__icon {
        display: flex;
        width: 24px;
        height: 24px;
        svg {
          stroke: #E5E8E8;
        }

        &--right {
          margin-left: 8px;
        }
        &--left {
          margin-right: 8px;
        }
    }
    &--trading-buy {
        background:  #00D496
    }
    &--trading-sell {
        background: #FD3232;
    }

    &--g8 {
      gap: 8px;
    }
    &--mt16 {
      margin-top: 16px;
    }
}


/*--------------End_Buttons---------*/




/*-----Checkbox and Radiobutton-----*/


.checkbox {
    margin: 0 0 10px 0;
    &__item {
        display: flex;
        align-items: center;justify-content: center;        
        flex: 0 0 20px;
        width: 20px;
        height: 20px;
        border: 2px solid var(--border-third);
        border-radius: 5px;
        cursor: pointer;
    }
    &__item-icon {
        display: flex;
        width: 12px;
        opacity: 0;
        .fill path {
            fill: #fff;
        }
    }
    &__label {
        display: flex;
        align-items: baseline;
        
    }
    &__text {
        position: relative;
        top: -1px;
        margin-left: 16px;
        font-size: 14px;
        cursor: pointer;
        color: var(--main-text-color);
        a {
            color: blue;
            text-decoration: underline !important;
        }
    }
    input:checked~.checkbox__item {
        background: var(--main-color);
        border-color: var(--main-color);
        .checkbox__item-icon {
            opacity: 1;
        }
    }
    input:disabled ~.checkbox__item{
        cursor: default;
        background: #b6b6b6;
        &+.checkbox__text {
            cursor: default;
        }
    }
    &--no-mb {
        margin-bottom: 0;
    }

    &--circle {
      margin: 0;

      .checkbox__item {
        position: relative;
        flex: 0 0 18px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 4px solid #255DF0;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;
          width: 4px;
          height: 4px;
          background-color: #255DF0;
        }
      }

      input:checked ~.checkbox__item{
        cursor: default;
        border: 4px solid #255DF0;
        background: #fff;
        &+.checkbox__text {
            cursor: default;
        }
        &::before {
          display: block;
        }
      }
    }

    &--type-2 {

    }
}

.radio {
    margin: 0 0 10px 0;
    &__item {
        position: relative;
        display: flex;
        align-items: center;justify-content: center;
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
        border: 1px solid var(--ui-checkbox-border);
        border-radius: 50%;
        cursor: pointer;
        &:after {
            content: '';
            position: absolute;
            top: 50%;left: 50%;
            transform: translate(-50%, -50%);
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #fff;
            z-index: 2;
            opacity: 0;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        
    }
    &__text {
        margin-left: 10px;
        font-size: 14px;
        cursor: pointer;
        a {
            color: blue;
        }
    }
    input:checked~.radio__item {
        background: var(--ui-checkbox-bg);     
        border-color: var(--ui-checkbox-bg);   
        &:after {
            opacity: 1;
        }
    }
    input:disabled ~.radio__item{
        cursor: default;
        background: #b6b6b6;
        &+.radio__text {
            cursor: default;
        }
    }

}

.switch {
    display: flex;
    align-items: center;
    
    &__toggler {
        position: relative;
        display: block;
        width: 36px;        
        height: 20px;    
        background: #2E3348;    
        border-radius: 18px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:before {
            content: "";
            position: absolute;
            left: 3px;
            top: 50%;transform: translateY(-50%);
            width: 14px;
            height: 14px;
            background: #E5E8E8;
            border-radius: 100%;
            transition: .3s;
        }                
    }
    input:checked~.switch__toggler {
        background: #B813F2;
        &:before {
            left: 18px;
            background: #fff;
        }
    }
    &__label {
        &+.switch__text {
            margin-left: 10px;
        } 
    }
    &__text {
        font-size: 14px;
        font-weight: 600;
        color: #A7A9AD;
        &+.switch__label {
            margin-left: 10px;
        }
    }

   
    &--type2 {
        .switch__toggler {
            background: var(--ui-swith-color);
            &:before {
                background: #fff;
            }
        }
        input:checked~.switch__toggler {
            background: var(--ui-main-color);
            &:before {
                background: #fff;
            }
        }
    }
}


/*---End_Checkbox and Radiobutton---*/



/*-------------Popup----------------*/


.popup-open {
    position: relative;
    overflow: hidden;
}

.popup-window {
    position: fixed;
    display: block ;    
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.8);
    overflow: auto;
    z-index: 201;    
    &__inside {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100%;
        padding: 20px ;
        z-index: 2;
    }
}

.popup {
    position: relative;
    width: 370px;
    max-width: 100%;
    border-radius: 10px;
    background: var(--ui-popup-bg);    
    padding: 24px;
    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 16px;
        svg {
            width: 100%;
            height: auto;
        }
        .fill path {
            fill: #000;
        }
    }
    &--size2 {
        width: 510px;
    }
    &--bigger {
      width: 501px;
    }
    &--big {
      max-width: 546px;
      width: 100%;
    }
}

.popup-close,
.popup-back {
    position: relative;
    margin-left: auto;    
    width: 12px;
    svg {
        width: 100%;
        height: auto;
    }
    .fill path {
        fill: var(--main-white-color);
    }
}

.popup-back {
  display: flex;
  width: 24px;
  margin-left: 0;
  margin-right: 16px;
}

.popup-header {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-third);
    padding-bottom: 16px;
    &__title {
        text-align: left;
        font-size: 20px;
        font-weight: 700;
        color: var(--main-white-color);
        &--center {
            text-align: center;
        }
    }

    &--type2 {
        justify-content: center;
        border: none;
        padding-bottom: 0;
        margin-bottom: 16px;
        .popup-close {
            position: absolute;
            top: 50%; right: 0;
            transform: translateY(-50%);
            margin-left: auto;
        }
    }
    // &--center {
    //   justify-content: space-between;
    //   .popup-close {
    //     margin-left: 0;
    //   }
    // }

    &--top {
      align-items: flex-start;
    }
}

.popup-body {
    margin-top: 18px;
    &--more-mt {
        margin-top: 24px;
    }
}

.popup-text {
    margin: 0 0 15px 0;
    line-height: 2;
    font-size: 15px;
    &--center {
        text-align: center;
    }
}

.form-text {
  font-weight: 600;
  font-size: 16px;
  color: #E5E8E8;
  a {
    color: #3498DB;
  }
  &--center {
    text-align: center;
  }
  &--thin {
    font-weight: 400;
    font-size: 14px;
    color: #A7A9AD;
  }
  &--df {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &--small {
    font-size: 12px;
    color: #A7A9AD;
  }
  &--mt8 {
    margin-top: 8px;
  }
}

.popup-submit {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    &--less-mt {
        margin-top: 15px;
    }
    &--more-mt {
        margin-top: 45px;
    }
    &__col {
        width: calc(50% - 10px);
    }
    &--sb {
        justify-content: space-between;
    }
}

@media screen and (max-width: 767px) {
    .popup {
        padding: 20px 15px;
    }

    .popup-header__title {
        font-size: 20px;
    }
}
/*-------------End_Popup------------*/


/*-------------Form, Authorization----------------*/


.authorization-section {
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding: 30px 0;
    &__container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }
}

.authorization {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    &__title {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
    }
    &__field {
        &+.authorization__field {
            margin-top: 20px;
        }
        &--numb{
            display: flex;
            justify-content: center;
            margin-bottom: 40px;
        }
    }
    &__details {
        margin-top: 16px;
        font-size: 14px;
        text-align: center;
        a, button {
            color: var(--ui-main-color);
            font-weight: 700;
        }
    }
    &__form {
        margin-top: 34px;
    }
    &__options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
    }
    &__options-full {
        width: 100%;
    }
    &__forgot-link {
        color: var(--ui-color-grey);
        font-size: 14px;
    }
    &__photo{
        margin: 25px 0;
        img{
            max-width: 100%;
        }
    }
}

.form-submit {
    margin-top: 34px;
}
/*-------------END_Form, Authorization----------------*/




/*-------------Notification----------------*/

.notification-wrapper{
    position: absolute;
    right: 25px;
    top: 25px;
    z-index: -100;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    &.active{
        visibility: visible;
        opacity: 1;
        z-index: 10000;
    }
}

.notification{
    position: relative;
    width: 410px;
    max-width: 100%;
    padding: 15px 20px;
    text-align: left;
    box-sizing: border-box;
    border-radius: 18px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 25px rgba(0, 0, 0, 0.15);
    background: #FFFFFF;
    transform: translateX(150%);
    transition: 0.5s;
    &.active{
        transform: translateX(0);
    }
    & + &{
        margin-top: 15px;
    }
    &--error, &--successful{
        .notification__title{
            color: #fff;
        }
        .notification__text{
            color: #fff;
        }
        .notification__close{
            svg{
                path{
                    fill: #fff;
                }
            }
        }
    }
    &--error{
        background: #EB5757;
    }
    &--successful{
        background: #4BC0B1;
    }
    &--icon-error{
        display: flex;
        align-items: stretch;
        padding: 0;
        .notification__icon{
            background: #EB5757;
        }
        .notification__info{
            padding: 15px;
        }
    }
    &--icon-successful{
        display: flex;
        align-items: stretch;
        padding: 0;
        .notification__icon{
            background: #4BC0B1;
        }
        .notification__info{
            padding: 15px;
        }
    }
    &__close{
        position: absolute;
        top: 10px;
        right: 10px;
    }
    &__title{
        font-weight: 600;
        font-size: 16px;
        color: #4C4C4C;
    }
    &__text{
        margin-top: 5px;
        font-size: 14px;
        line-height: 150%;
        color: #4C4C4C;
    }
    &__icon{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 62px;
        min-width: 62px;
        border-radius: 18px 0 0 18px;
        svg{
            width: 30px;
            margin: 0 auto;
        }
    }
}



/*-------------END_Notification----------------*/


/*------------Pagination---------------------*/


.pagination-block {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    &--end {
        justify-content: flex-end;
    }
    &--start {
        justify-content: flex-start;
    }
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    >li {
        padding: 0 5px;
        >a,>button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            min-width: 40px;
            margin-top: 10px;
            padding: 0 2px;
            border: 1px solid var(--ui-pagination-border);
            border-radius: 3px;
            background: var(--ui-pagination-bg);
            color: var(--ui-pagination-color);
            transition: all 0.15s ease-in;
        }
        &.arrow {
            svg {
                width: 24px;
                height: auto;
            }
            .stroke path {
                stroke:  var(--ui-pagination-color);
                transition: all 0.15s ease-in;
            }
            &.disabled {
                >a,>button {
                    pointer-events: none;
                }
                svg {
                    opacity: 0.3;
                }
            }
            
        }
        &:not(.disabled){
            &:hover, &.active {
                >a,>button {
                    // background: var(--ui-main-color);
                    color: #fff;
                    // border-color: var(--ui-main-color);
                }
                .stroke path {
                    stroke: #fff;
                }
            }
            
        }
    }
}
/*------------END_Pagination----------------*/


/*------------------Captcha----------------*/
.captcha-modal {
    position: relative;
    width: 360px;   
    background-color: var(--ui-popup-bg);
    box-shadow: 0px 0px 19.32px 1.68px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    color: var(--ui-text-color);
    padding: 15px;
}

.captcha-cover {
    position: relative;
    height: 220px;
    padding: 0 15px;
    &__item {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    } 
}

.captcha-text {
    position: absolute;
    top: 15px;
    left: 0;
    padding: 0 15px;
    z-index: 2;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    span {
        color: var(--ui-main-color);
    }
}

.captcha-move {
    position: absolute;
    bottom: 20px;
    left: 0%;
    margin-left: 10px;
    width: 60px;
    z-index: 2;
}

.capthca-default {
    position: absolute;
    bottom: 20px;
    left: 60%;
    width: 60px;
    margin-left: 10px;
}

.capthca-slide-block {
    padding: 20px;
}

.capthca-slide {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding-left: 40px;
    border-radius: 25px;
    background: #EEEFEE;
    border: 1px solid var(--ui-border-color);
    text-align: center;
    font-size: 15px;
    color: rgba(150,150,150,0.5);
}

.slide-btn {
    position: absolute;
    margin-left: -10px;
    top: 50%; transform: translateY(-50%);
    left: 0;
    display: flex;
    align-items: center;justify-content: center;
    width: 64px;
    height: 64px;    
    border-radius: 50%;
    background: var(--ui-main-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;      
    svg {
        width: 24px;
        height: auto;
        pointer-events: none;

    }
    .stroke path {
        stroke: #fff;
    }
    
    &.success{
        .stroke path {
            stroke: #fff;
        }
    }
    &.error {
        .stroke path {
            stroke: #ed1c24;
        }        
    }

}

.capthca-actions {
    display: flex;
    padding:  15px 15px 0 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
    border-top: 1px solid var(--ui-border-color);
    &__item {
        display: flex;
        align-items: center;
        &+.capthca-actions__item {
            margin-left: 15px;
        }
        svg {
            width: 20px;
            height: auto;
        }
        svg path {
            stroke: #121214;
        }
    }
}
/*------------------End_Captcha------------*/


/*------------------Avatars------------*/

.user-avatar {
    display: flex;
    align-items: center;
    &--xl-size {
        .user-avatar__info-name {
            font-size: 16px;
        }
        .user-avatar__info-text{
            font-size: 12px;
        }
    }
    &--m-size {
        .user-avatar__info-name {
            font-size: 14px;
        }
        .user-avatar__info-text{
            font-size: 11px;
        }
    }
    &--s-size{
        .user-avatar__info-name {
            font-size: 12px;
        }
        .user-avatar__info-text{
            font-size: 9px;
        }
    }
    &--xs-size {
        
    }
    &__info {
        padding-left: 10px;
    }
    &__info-name {
        color: var(--ui-text-color);
        font-weight: 700;
        line-height: 1.4;
    }
    &__info-text {
        color: var(--ui-text-color-light);
    }
}

.avatar {
    position: relative;
    display: flex;
    align-items: center;justify-content: center;
    border-radius: 50%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
    &--xl-size {
        width: 48px;
        height: 48px;
    }
    &--m-size {
        width: 40px;
        height: 40px;
        .avatar__text {
            font-size: 16px;
        }
    }
    &--s-size{
        width: 32px;
        height: 32px;
        .avatar__text {
            font-size: 14px;
        }
    }
    &--xs-size {
        width: 24px;
        height: 24px;
        .avatar__text {
            font-size: 12px;
        }
    }
    &--xxs-size {
        width: 16px;
        height: 16px;
        .avatar__text {
            font-size: 8px;
        }
    }
    &__text {
        color: #fff;
        font-weight: 700;
        text-align: center;
    }
    &--no-photo {
        background: var(--ui-main-color);
    }
    &__text {
        font-size: 20px;
    }
}

.avatars-group {
    display: flex;
    .avatar {
        border: 2px solid #fff;
        z-index: 10;
        &:nth-child(2) {
            z-index: 9;
        }
        &:nth-child(3) {
            z-index: 8
        }
        &:nth-child(3) {
            z-index: 7
        }
        &:nth-child(4) {
            z-index: 6
        }
        &:nth-child(5) {
            z-index: 5;
        }
    }
    &--xl-size {
        .avatar + .avatar {
            margin-left: -20px;
        }  
    }
    &--m-size {
        .avatar + .avatar {
            margin-left: -15px;
        }  
    }
    &--s-size {
        .avatar + .avatar {
            margin-left: -10px;
        }  
    }
}

/*------------------End_Avatars------------*/


/*----------------Blog_and_article------------*/

.blog-section {
    padding: 45px 0;
}

.blog-container {  
    margin: 0 auto;
}

.blog {    
    padding-top: 20px;
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -30px;
    }
    &__col {
        width: 33.3%;
        padding: 0 30px;
    }
}

.blog-card {
    margin-top: 30px;
    &__img {
        position: relative;
        height: 211px;
        img {
            width: 100%;height: 100%;
            border-radius: 18px;
            object-fit: cover;
        }
    }
    &__title {
        display: block;
        margin-top: 20px;
        font-weight: 600;
        font-size: 28px;
        line-height: 150%;
        color: #E5E8E8;
        transition: all 0.15s ease-in;
    }
    &__logo {
      position: absolute;
      top: 24px;
      left: 24px;
      width: 94px;
    }
    &__text {
      margin-top: 24px;
      font-weight: 500;
      font-size: 14px;
      color: #A7A9AD;
    }
}

@media screen and (max-width: 991px) {
    .blog {
        &__row {
            margin: 0 -15px;
        }
        &__col {
            padding: 0 15px;
        }
    }

    .blog-card {
        margin-top: 20px;
        &__title {
            font-size: 17px;
        }
    }
}

@media screen and (max-width:767px) {
    .blog-section {
        padding: 30px 0;
    }

    .blog {      
        padding-top: 10px;  
        &__col {
            width: 50%;
        }
    }
}

@media screen and (max-width:475px) {    

    .blog {              
        &__col {
            width: 100%;
        }
    }
}

/*----------------End_Blog_and_article------------*/


/*----------------Text and title------------*/

.section-title {    
    font-size: 42px;
    font-weight: 700;    
    line-height: 1.4;
    &--centered {        
        text-align: center;
    }
    &--right {       
        text-align: right;
    }
}

.block-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.4;
    &--centered {        
        text-align: center; 
    }
    &--right {        
        text-align: right;
    }
}

.h1, .h2,.h3,.h4,.h5 {
    font-weight: 700;
    line-height: 1.4;
}

.h1 {
    font-size: 42px;
}
.h2 {
    font-size: 36px;
}
.h3 {
    font-size: 30px;
}
.h4 {
    font-size: 24px;
}
.h5 {
    font-size: 18px;
}

.text-block {
    margin-top: 30px;
    line-height: 2;
    font-size: 15px;
    &--mt15 {
        margin-top: 15px;
    }
    &--no-mt {
        margin-top: 0;
    }
    p {
        &+p {
            margin-top: 15px;
        }
        a {
            color: var(--ui-main-color);
            text-decoration: underline !important;
            font-weight: 700;
        }       
    }
    
    &--centered {
        text-align: center;
    }
    &--right {
        text-align: right;
    }
}

.bold {
    font-weight: 700;
}

/*----------------End_Text and title------------*/


/*----------------Loader-----------------------*/

.loader-modal {
    position: fixed;
    display: flex;    
    align-items: center;justify-content: center;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0,0,0,0.65);
    z-index: 210;
}

.loader-circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {    
      width: 100px;
      height: 100px;
      overflow: visible;
      transform: rotate(-90deg);
    }
    &__item {      
        width: 100px;
        height: 100px;
        stroke-dasharray: 314;      
      &--type1 {       
        fill: none;
        stroke-width: 6px; 
        stroke: rgba(255, 255, 255, 0.2);
        
      }
      &--type2 {              
        fill: none;
        stroke-width: 8px;
        stroke: var(--ui-main-color);       
        stroke-linecap: round;               
        transform-origin: center center;
        animation:  loader-stroke 3s infinite linear, loader-rotation 2s infinite cubic-bezier(.45,.85,.72,.71) ;         
      }
    }   
    &--md {
        svg {
            width: 70px;
            height: 70px;
        }
        .loader-circle__item {
            width: 70px;
            height: 70px;
            stroke-dasharray: 220px;  
            &--type1 {
                stroke-width: 5px; 
            } 
            &--type2 {
                stroke-width: 6px;
                animation:  loader-stroke-md 2.6s infinite linear, loader-rotation 1.8s infinite cubic-bezier(.45,.85,.72,.71) ;        
            }
        }
    }
    &--sm {
        svg {
            width: 40px;
            height: 40px;
        }
        .loader-circle__item {
            width: 40px;
            height: 40px;
            stroke-dasharray: 126px;  
            &--type1 {
                stroke-width: 3px; 
            } 
            &--type2 {
                stroke-width: 4px;
                animation:  loader-stroke-sm 2.4s infinite linear, loader-rotation 1.6s infinite cubic-bezier(.45,.85,.72,.71) ;        
            }
        }
    }
}

@keyframes loader-rotation {
    0% {            
      transform: rotate(0);
    }
    100%{
      transform: rotate(360deg);
    }
}

@keyframes loader-stroke {
    0% {      
      stroke-dashoffset: 314;      
    }
    100%{    
      stroke-dashoffset: -314;
      
    }
}

@keyframes loader-stroke-md {
    0% {      
      stroke-dashoffset: 220;      
    }
    100%{    
      stroke-dashoffset: -220;
      
    }
}

@keyframes loader-stroke-sm {
    0% {      
      stroke-dashoffset: 126;      
    }
    100%{    
      stroke-dashoffset: -126;
      
    }
}
/*----------------End_Loader-------------------*/

/*----------------File_upload-------------------*/

.upload-file-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;
    width: 370px;
    overflow: hidden;
    border: 1px dashed var(--ui-input-border-color);
    border-radius: 10px;
    transition: all 0.15s ease-in;
    cursor: pointer;
    &:hover {
        border-color: var(--ui-upload-box-color);
        .upload-file__label {
            color: var(--ui-upload-box-color);
        }
    }
    &:focus {
        border-style: solid;
    }
    &--error {
        border-color: var(--ui-error-color) !important;
        .upload-file__label {
            color: var(--ui-text-color) !important;
        }
    }
}

.error-text {
    margin-top: 4px;
    font-size: 14px;
    color:  var(--ui-error-color);
}

.file-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-indent: -999px;
    z-index: 5;
    
}

.upload-file {
    width: 100%;
    padding: 20px 40px;
    text-align: center;
    &__icon {
        display: flex;
        align-items: center;
        width: 30px;
        margin: 0 auto;
        svg {
            width: 100%;
            height: auto;
        }
    }
    &__title {
        display: block;
        margin-top: 15px;
        font-size: 18px;
        font-weight: 500;
        color: var(--ui-text-color);
    }
    &__text {
        display: block;
        margin-top: 15px;
        font-size: 14px;
        color: var(--ui-text-color-light);
    }
    &__label {
        display: inline-block;
        margin-top: 16px;
        padding: 6px 20px;
        border: 1px solid var(--ui-input-border-color);
        border-radius: 4px;
        box-shadow: 0px 1px 2px rgba(6, 25, 56, 0.05);
        font-size: 14px;
        font-weight: 700;
        transition: all 0.15s ease-in;
    }
    .progress-bar {
        margin-top: 16px;
    }    
}

.upload-photo-wrap {
    position: relative;
}

.upload-photo-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 170px;
    width: 260px;
    overflow: hidden;
    border: 1px dashed var(--ui-input-border-color);
    border-radius: 4px;
    transition: all 0.15 ease-in;
    cursor: pointer;
    .loader-circle__item--type1 {
        stroke: #e0e0e0;
    }
    &--preview {
        border-color: transparent;
    }
    &--error {
        background: #FFF4F4;
        border-color: transparent;
        .upload-photo__info {
            color: var(--ui-error-color);
        }
    }
}

.upload-photo {
    text-align: center;
    &__icon {
        display: flex;
        align-items: center;justify-content: center;
        margin: 0 auto;
        width: 55px;
        svg {
            width: 100%;
            height: auto;
        }
        &--error {
            height: 55px;
            border: 2px solid #EED5D5;
            border-radius: 50%;
            svg {
                width: 30px;
            }
        }
    }
    &__info {
        display: block;
        margin-top: 16px;
        font-size: 13px;
        color: #9EA2A9;
    }
    &__info-text {
        display: block;
        margin-top: 4px;
        font-weight: 500;
    }
    
}

.upload-photo-preview {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.upload-photo-hint {
    margin-top: 4px;
    font-size: 12px;
    color: var(--ui-text-color-grey);
}

.upload-photo-action {
    position: absolute;
    display: flex;
    align-items: center;    
    right: 10px;
    bottom: 10px;
    z-index: 6;
    &__btn {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        cursor: pointer;
        &+.upload-photo-action__btn {
            margin-left: 10px;
        }
        &--edit {
            background: var(--ui-main-color);
            svg {
                width: 15px;
            }
            .fill path {
                fill: #fff;
            }
        }
        &--remove {
            background: #fff;
            svg {
                width: 11px;
            }
            .stroke path {
                stroke: var(--ui-text-color);
            }
        }
        svg {
           
            height: auto;
        }
    }
}

.file-attachment {
    display: inline-flex;
    margin: 15px 0;
    label {
        cursor: pointer;
    }
    &__title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: var(--ui-main-color);
        svg {
            width: 15px;
            margin-left: 5px;
        }
        .stroke path {
            stroke: var(--ui-main-color);
        }
    }
    &__text {
        margin-top: 6px;
        font-size: 14px;
        color: var(--ui-text-color-light);
    }
}

/*----------------End_File_upload---------------*/


/*----------------Progress-bar-----------------*/
.progress-bar {
    position: relative;
    display: block;
    &__line {
        display: block;
        position: relative;
        height: 1px;
        background: var(--bg-third);
        border-radius: 2px;
    }
    &__line-active {
        position: absolute;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 3px;
        background: #3498DB;
        border-radius: 2px;
        transition: all 0.15s linear;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    

    &__dots {
      
      position: absolute;
      top: 50%;transform: translateY(-50%);  
      width: 100%;    
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 2;
    }

    &__dot {
    
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: var(--bg-third);
      cursor: pointer;
      &.active {        
        background: #3498DB !important;
        
        .progress-bar-label {
          color: #3498DB;
        }
      }
      &:first-child {
        .progress-bar-label {
            justify-content: flex-start;
        }
      }
      &:last-child {
        .progress-bar-label {
            justify-content: flex-end;
        }
      }
    }
    &__current {
        position: absolute;
        top: 50%;
        transform: translate(-4px, -50%);
        width: 11px;
        height: 11px;
        background: #3498DB;
        border-radius: 50%;
        z-index: 3;
    }
    &--slides {
        margin-bottom: 25px;
        cursor: pointer;
    }
}

.progress-bar-label {
    position: relative;
    // bottom: calc(100% + 10px);
    bottom: -20px;    
    display: flex;
    justify-content: center;
    text-align: center;
    color: #2E3348;
    font-weight: 600;
    font-size: 10px;
    line-height: 160%;
    background: transparent;
}

/*----------------End_Progress-bar-------------*/

/*----------------FAQ--------------------------*/


.faq-box{
    margin-top: 50px;
    padding: 50px 15px;
    background: #F2F2F2;
    border-radius: 18px;
}

.faq-list {
    max-width: 650px;
    margin: 0 auto;
}

.faq {
    padding: 15px;
    background: #fff;   
    border: 1px solid var(--ui-border-light-color);    
    border-radius: 10px;
    &__header {
        display: flex;
        align-items: center;
        
    }
    &__header-title {
        font-weight: 600;
        flex: 1 1 0;
    }
    &__header-btn {
        display: flex;
        flex: 0 0 16px;
        max-width: 16px;
    }
    &__text {
        display: none;  
        border-top: 1px solid transparent;    
        font-size: 14px;     
    }
    &.active {
        .faq__header-btn {
            transform: rotate(-180deg);
        }
        .faq__text {       
            display: block;     
            margin-top: 10px;
            padding-top: 10px;        
            border-color:  var(--ui-border-light-color);     
        }
    }
    &+.faq {
        margin-top: 15px;
    }
}

@media screen and (max-width:767px) {    

    .faq-box {
        margin-top: 30px;
        padding: 30px 15px;
    }
}
/*----------------END_FAQ----------------------*/

/*----------------Tooltip----------------------*/

.tooltip-wrap {
    &--right-align {
        text-align: right;
    }
    &--center-align {
        text-align: center;
    }
}

.tooltip-item {
    position: relative;
    display: inline-flex;
    &__title {
        font-weight: 600;
    }
    &__icon {
        display: inline-flex;
        align-items: center;
        width: 16px;
    }
    &:hover {
        .tooltip {
            display: block;
        }
    }   
    
    &--bigger {
      .tooltip-item__icon {
        width: 24px;
        svg {
          stroke: #fff;
        }
      }
    }
}

.tooltip {
    position: absolute;
    display: none;
    width: 310px;
    background: var(--ui--tooltip-bg);
    border-radius: 8px;
    padding: 8px 12px;
    z-index: 10;
    &__text {
        display: block;
        text-align: left;
        color: #A7A9AD;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.6;
    }
    &__text-title {
      color: #E5E8E8;
    }
    &:after {
        display: none;
        content: '';
        position: absolute;   
        border: 6px solid transparent; border-bottom: 6px solid var(--ui--tooltip-bg);        
    }
    &--bottom {
        top: calc(100% + 10px);
    }
    &--top {
        bottom:  calc(100% + 10px);
    }
    &--center {
        left: 50%;
        transform: translateX(-50%);
    }
    &--left {
        left: 0;
    }
    &--right {
        right: 0;
    }
    &--arrow-top-left {        
        &:after {
            top: -12px;
            left: 20px;
        }
    }
    &--arrow-top-right {        
        &:after {
            top: -12px;
            right: 20px;
        }
    }
    &--arrow-top-center {
        &:after {
            top: -12px;
            left: 50%;
            transform: translateX(-50%);
        }
        
    }
    &--arrow-bottom-left {        
        &:after {
            bottom: -12px;
            left: 20px;
            transform: rotate(-180deg);
        }
    }
    &--arrow-bottom-right {        
        &:after {
            bottom: -12px;
            right: 20px;
            transform: rotate(-180deg);
        }
    }
    &--arrow-bottom-center {
        &:after {
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%) rotate(-180deg);
        }
        
    }
}

@media screen and (max-width:475px) {    

    .tooltip {
        max-width: 280px;
        font-size: 11px;
    }
}
/*----------------END_Tooltip------------------*/



