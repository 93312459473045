
.steps-info__list li{
  padding-bottom: 35px;
  font-weight: 100;
}
.steps-info__list a{
  color:#b813f2
}
.transfer__item-ballance{
  color: #A7A9AD;
  font-size: 12px;
}
.transfer__count-number{
  display: flex;
  justify-content: end;
  
}
.transfer__count-input{
  width: auto;
}
.transfer__count-asset{
  padding-left: 10px;
}
.allocations{
  color:#FD3232;
}
.transfer__count-warning{
  color:#FD3232;
}
// .button--medium-height.not-active{
//   background-color: #A7A9AD;
// }