.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.spoiler {
	color: #66cbff6b;
	background-color: #6b7384;
	font-size: calc(10px + 2vmin);
	font-weight: bold;
	border: 1px solid #6b7384;
	border-radius: 5px;
	padding: 5px;
	margin: 5px;
}

.main-content {
	margin: 0 15px;
}
